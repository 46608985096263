<template>
  <v-card class="fill-height">
    <v-title title="ФинСервисSF"/>
    <iframe class="fill-height modal-height fill-width b-0" src="https://fin-servis-sf.fin-success.biz/?clear=true"/>
  </v-card>
</template>

<script>
import VTitle from "../../tags/VTitle";

export default {
  name:       "Index",
  components: {VTitle},
}
</script>

<style scoped>
.b-0 {
  border: 0;
}

.modal-height {
  min-height: calc(100vh - 138px);
}
</style>