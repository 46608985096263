<template>
  <v-card>
    <v-title title="Список клиентов"/>
    <v-card-title>
      <span class="mr-5">Список клиентов</span>
      <span>
      <client-form ref="create" @save="onCreate">
        <btn-icon :title="$t('btn.create')" color="success" icon="plus"/>
      </client-form>
      </span>
      <toggle-deleted v-if="can('client.delete')" v-model="showDeleted" class="ml-2"/>
      <btn-icon :icon="!showFilter?'filter-outline':'filter-off-outline'" :title="showFilter?'Скрыть фильтр':'Показать фильтр'" class="ml-2" color="info" @click="toggleFilter"/>
      <stat-view>
        <btn-icon v-if="showStatView" class="ml-2" color="warning" icon="chart-box-outline" title="Статистика"/>
      </stat-view>
      <calculator-view>
        <btn-icon v-if="showCalculatorView" class="ml-2" color="success" icon="calculator" title="Калькулятор"/>
      </calculator-view>

      <template v-if="!showMobile">
        <deal-modal v-if="showDeal">
          <btn-icon class="ml-2" color="accent" icon="handshake-outline" title="Сделки"/>
        </deal-modal>

        <link-modal v-if="showLink">
          <btn-icon class="ml-2" color="info" icon="check-decagram-outline" title="Верификация"/>
        </link-modal>

        <fin-service-modal v-if="showFinService">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-img :src="require('@/assets/fin-service.svg')" alt="Финансовые услуги" class="ml-3" v-bind="attrs" width="22px" v-on="{...on}"/>
            </template>
            ФинСервисSF
          </v-tooltip>
        </fin-service-modal>
      </template>
      <v-spacer/>
      <v-text-field
          v-model="search"
          :label="$t('btn.search')"
          append-icon="mdi-magnify"
          clearable
      />
      <client-view :id="createdID" ref="view" @update="models.load()"/>
    </v-card-title>
    <v-expand-transition>
      <client-filter-form v-model="searchParams" :class="showFilter?'':'d-none'" @input="updateFilter"/>
    </v-expand-transition>
    <data-table
        :headers="headers"
        :models="models"
    >
      <template v-slot:item.status="{ item }">
        <v-chip v-if="item.status" :color="item.status.color" dark>{{ item.status.name }}</v-chip>
      </template>
      <template v-slot:item.type="{ item }">
        <v-chip v-if="item.type" :color="item.type.color" dark>{{ item.type.name }}</v-chip>
      </template>
      <template v-slot:item.category="{ item }">
        <v-chip v-if="item.category" :color="item.category.color" dark>{{ item.category.name }}</v-chip>
      </template>
      <template v-slot:item.notice_date="{ item }">
        {{ utcToDatetime(item.notice_date) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="text-right">
          <span class="ml-2">
            <client-view :id="item.id" can-duplicate="true" @update="models.load()" @copy-profile="createFromData">
              <btn-icon color="info" icon="information" title="Подробности"/>
            </client-view>
          </span>
          <span v-if="canEdit(item)" class="ml-2">
            <client-form :id="item.id" @save="models.load()">
              <btn-icon :title="$t('btn.edit')" color="primary" icon="pencil"/>
            </client-form>
          </span>
          <span v-if="!item.deleted_at" class="ml-2">
            <favorite :id="item.id" :favorite="item.isFavorite" @update="models.load()"/>
          </span>
          <span v-if="canDelete(item)" class="ml-2">
            <delete :id="item.id" :name="item.fullName" @remove="models.load()"/>
          </span>
          <span v-if="item.deleted_at" class="ml-2">
            <restore :id="item.id" :name="item.fullName" @restore="models.load()"/>
          </span>
        </div>
      </template>
    </data-table>
  </v-card>
</template>

<script>
import {Search}   from "@/models/Search";
import {debounce} from "lodash/function";

import Delete            from "@/views/clients/Delete";
import {mapGetters}      from "vuex";
import ToggleDeleted     from "@/views/clients/ToggleDeleted";
import Restore           from "@/views/clients/Restore";
import DataTable         from "@/tags/DataTable";
import VTitle            from "@/tags/VTitle";
import BtnIcon           from "@/tags/BtnIcon";
import ClientForm        from "./ClientForm";
import ClientView        from "@/views/clients/ClientView";
import Favorite          from "@/views/clients/Favorite";
import ClientFilterForm  from "@/views/clients/ClientFilterForm";
import StatView          from "@/views/clients/StatView";
import {CLIENT_STATUSES} from "@/models/ClientStatus";
import CalculatorView    from "@/views/calculator/CalculatorView.vue";
import DealModal         from "@/views/deals/DealModal.vue";
import LinkModal         from "@/views/links/LinkModal.vue";
import FinServiceModal   from "@/views/finService/FinServiceModal.vue";


export default {
  name:       "Index",
  components: {FinServiceModal, LinkModal, DealModal, CalculatorView, StatView, ClientFilterForm, Favorite, ClientView, ClientForm, BtnIcon, VTitle, DataTable, Restore, ToggleDeleted, Delete},
  data:       () => ({
    search:      '',
    models:      Search.create({
      uri:    'client',
      fields: [
        'id',
        'fullName',
        'notice_date',
        'isFavorite',
        'status_id',
        'status.name',
        'status.color',
        'category.name',
        'category.color',
        'type.name',
        'type.color',
        'deleted_at'
      ],
      expand: ['status', 'type', 'category'],
      params: {deleted: 0, comment: '', search: ''},
      filter: {}
    }),
    showDeleted: 0,
    showFilter:  true,
    searchParams:     {filter: {}, params: {}},
    createdID:        undefined,
    dateCreatedStart: undefined,
    dateCreatedEnd:   undefined
  }),
  computed:   {
    ...mapGetters(['can', 'role']),
    headers() {
      return [
        {text: 'ФИО', value: 'fullName'},
        {text: 'Статус', value: 'status'},
        {text: 'Тип лида', value: 'type'},
        {text: 'Категория', value: 'category'},
        {text: 'Дата уведомления', value: 'notice_date'},
        {text: 'Действия', value: 'actions', align: 'end'}
      ]
    },
    showStatView() {
      return this.role?.name !== 'agent';
    },
    showCalculatorView() {
      return this.can('calculator.manage');
    },
    showMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    showDeal() {
      return this.can('deal.manage');
    },
    showLink() {
      return this.can('link.view');
    },
    showFinService() {
      return this.can('fin-service.view');
    }
  },
  watch:      {
    search(value) {
      this.models.params.comment = value || '';
      this.models.params.search = value || '';
      this.models.config.filter = Object.assign({}, this.searchParams.filter)
      this.update(this)
    },
    showDeleted(value) {
      this.models.params.deleted = value;
      this.models.load();
    }
  },
  methods:    {
    update: debounce((vm) => vm.models.load(), 200),
    utcToDatetime(value) {
      if (!value) {
        return 'Не указано';
      }
      return this.$d(value * 1000, 'datetime');
    },
    canDelete(item) {
      return !item.deleted_at && this.can('client.delete')
    },
    canEdit(item) {
      const isDeleted           = item.deleted_at !== null;
      const isAgent             = this.can('client.agent');
      const isCompletedOrDenied = [CLIENT_STATUSES.COMPLETE, CLIENT_STATUSES.DENIED].includes(item.status_id);
      const canManageCompleted  = this.can('client.manage.completed');

      return !isDeleted && !isAgent && (!isCompletedOrDenied || canManageCompleted);
    },
    onCreate(id) {
      this.models.load();
      this.createdID = id;
      this.$refs.view.modal = true;
    },
    updateFilter() {
      this.models.config.filter = Object.assign({}, this.searchFilter, this.searchParams.filter);
      this.models.params = Object.assign({}, this.models.params, this.searchParams.params);
      this.update(this);
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    createFromData(data) {
      const form = this.$refs.create;
      form.modal = true;
      this.$nextTick(() => form.fillProfile(data));
    }
  }
}
</script>

<style scoped>

</style>