<template>
  <modal v-model="modal" :loading="model.loading" :title="id?'Изменение новости':'Создание новости'" max-width="1000px">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-card-text class="pt-3">
      <v-form ref="form" :disabled="model.loading" @submit.prevent="model.save()">
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="model.attr.title" :label="model.labels.title" :rules="model.rule('title')"/>
          </v-col>
          <v-col col="12">
            <tiny-editor v-if="editor" v-model="model.attr.text"/>
          </v-col>
          <v-col cols="12">
            <datetime-utc-input v-model="model.attr.published_at" :label="model.labels.published_at" :rules="model.rule('published_at')" mask>
              <template #append-outer>
                <btn-icon color="secondary" icon="clock-time-four-outline" title="Сейчас" @click="publishNow()"/>
              </template>
            </datetime-utc-input>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <template v-slot:actions>
      <v-btn :disabled="model.loading" color="primary" @click="model.save()">{{ $t('btn.save') }}</v-btn>
    </template>
  </modal>
</template>

<script>
import Modal            from "@/tags/Modal";
import TinyEditor       from "@/tags/TinyEditor";
import {Faq}            from "@/models/Faq";
import DatetimeUtcInput from "@/tags/DatetimeUtcInput.vue";
import BtnIcon          from "@/tags/BtnIcon.vue";


export default {
  name:       "NewsForm",
  components: {BtnIcon, DatetimeUtcInput, TinyEditor, Modal},
  props:      ['id'],
  data() {
    return {
      modal:  false,
      editor: false,
      model:  Faq.get(this.id, {
        on: {
          save:  () => {
            this.close();
            this.$emit('save');
          },
          error: () => {
            this.$refs.form.validate();
          }
        }
      })
    }
  },
  computed: {},
  watch:    {
    modal(value) {
      if (value) {
        setTimeout(() => this.editor = true, 0)
        if (this.id) {
          this.model.load();
        } else {
          this.model.clear();
          this.model.fill({published_at: null})
        }
      } else {
        setTimeout(() => this.editor = false, 0)
      }
    }
  },
  methods:  {
    close() {
      this.$refs.form.reset();
      this.modal = false
    },
    publishNow() {
      this.model.attr.published_at = Math.floor(new Date().getTime() / 1000);
    }
  }
}
</script>

<style scoped>

</style>