<template>
  <modal v-model="modal" :title="false" max-width="1200px">
    <template v-slot:activator>
      <slot/>
    </template>
    <DealIndex/>
    <template v-slot:actions>
      <v-btn color="primary" @click="close">Закрыть</v-btn>
    </template>
  </modal>
</template>

<script>

import Modal     from "@/tags/Modal.vue";
import DealIndex from "@/views/deals/Index.vue";

export default {
  name:       "DealModal",
  components: {DealIndex, Modal},
  data() {
    return {
      modal: false,
    }
  },
  methods: {
    close() {
      this.modal = false;
    }
  }
}
</script>


<style scoped>

</style>